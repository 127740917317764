<template>
    <div class="inApp_desktop">
        <BrowserWarning />

        <!-- ADMIN FEATURE // CHANGE ACCOUNT USER IN STORE -->
        <DatalistUser v-if="isAdmin" />

        <!-- Right Nav Bar -->
        <Navbar />

        <!-- Onboarding dashboard -->
        <Onboarder />

        <!-- Router view -->
        <div class="router-view-in-app" id="modalZone">
            <div class="subscriptionInfosContainer">
                <SubscriptionInfos v-if="currentRoute != 'edit'" :user="getCurrentUser" class="subscriptionInfos"/>
            </div>
            <router-view style="position:relative"></router-view>
        </div>


    </div>
</template>


<script>
//STORE
import store from "../store"
import Vuex from "vuex";

//COMPONENTS
import Navbar from '../components/Object/Navbar/Navbar.vue'
import DatalistUser from "../components/Object/datalistUser.vue"
import BrowserWarning from "../components/Object/BrowserWarning/BrowserWarning.vue"
import SubscriptionInfos from "../components/Object/SubscriptionInfos.vue"
import Onboarder from '../components/Object/Onboarder/Onboarder.vue';

export default {
    components: {
        Navbar,
        DatalistUser,
        BrowserWarning,
        SubscriptionInfos,
        Onboarder
    },
    store: store,
    computed: {
        ...Vuex.mapGetters([
            "isAdmin",
            "isSubscribed",
            "getCurrentUser"
        ]),
        currentRoute() {
            return this.$router.currentRoute.value.path.split("/").filter(p => p != "")[0]
        }
    },
    mounted() {
        //console.log("[In app] Current user ", this.getCurrentUser)
    }
}

</script>

<style>
body,
html {
    background-color: var(--color_background) !important;
    overflow-y: hidden
}

.inApp_desktop {
    overflow-y: hidden
}

.router-view-in-app {
    position: relative;
    z-index: var(--zindex_routerView);
    float: left;
    height: 100%;
    left: 0px;
    width: 100%;
    border-radius: 0px;
    filter: drop-shadow(-10px 0px 10px #0000002c);
    background-color: var(--color_background);


    --left_offset: 2px;
    left: calc(var(--nav_width) - var(--left_offset));
    width: calc(100% - var(--nav_width) + var(--left_offset));
    border-radius: var(--border_radius) 0px 0px var(--border_radius);

    overflow-x: hidden;
}

.subscriptionInfosContainer{
    position: absolute;
    top: 17px;
    right: 20px;
}
</style>

<style src="../styles/verticalBanner.css" ></style>