<template>
  <div id="nav" class="home flex" >

    
    

      <a @click="$router.push('/')"><img :src='require("../../../assets/" + domain_customization.logo_path)' style="margin-top: 30px" /></a>
  
    <!--
      <a @click="$router.push('/')"><img src="../../../assets/yopbox_logo_white.svg" style="margin-top: 30px" /></a>
    -->
    <br />
    <div id="nav_top" class="flex">
      <router-link to="/">
        <img src="../../../assets/home_white.png" v-tippy="{ placement: 'right', content: $t('Home.dashboard') }" />
      </router-link>
      <br />
      <router-link to="/videos">
        <img src="../../../assets/videos.svg" v-tippy="{ placement: 'right', content: $t('VideoLibrary.title') }" />
      </router-link>
      <br />
      <router-link to="/identity">
        <img src="../../../assets/identity.svg" v-tippy="{ placement: 'right', content: $t('Identity.title') }" />
      </router-link>
      <br />
      <router-link to="/settings">
        <img src="../../../assets/gear_white.svg"
          v-tippy="{ placement: 'right', content: $t('DefaultSettings.tippy') }" />
      </router-link>
    </div>
    <div id="nav_bottom" class="flex">
      <div class="help" @click="show_hide_onboarding_dashboard">
        <div v-if="remaining_tasks_count != 0" class="badge_container">
          <span class="task_count_badge">{{ remaining_tasks_count }}</span>
        </div>
        <img src="../../../assets/trophy_color.svg" v-tippy="{
          placement: 'right', content: $t('onboarding.yopboxChallenge')
        }" />
      </div>
      <br />
      <router-link to="/profile">
        <img src="../../../assets/profil_white.svg" v-tippy="{ placement: 'right', content: $t('Profile.title') }" />
      </router-link>
      <br />
      <router-link to="/admin" v-if="isAdmin">
        <img src="../../../assets/admin.png" v-tippy="{ placement: 'right', content: 'Admin' }" />
      </router-link>
      <br v-if="isAdmin" />
      <img src="../../../assets/logout_white.png" class="disconnect" @click="logOut"
        v-tippy="{ placement: 'right', content: $t('Logout') }" />
    </div>


  </div>
</template>


<script>
//STORE
import Vuex from "vuex";

export default {
  computed: {
    ...Vuex.mapGetters(["isAdmin", "remaining_tasks_count"]),
  },
  methods: {
    ...Vuex.mapActions(["clean_Token_And_Usr_Info", "show_hide_onboarding_dashboard"]),
    logOut() {
      this.clean_Token_And_Usr_Info();
      //let tokenCookie = this.$cookies.get("token")
      this.$cookies.remove("token", null, this.TOKEN_COOKIE_DOMAIN);
      //this.$cookies.remove("token");
      this.$router.push({ name: "Login" });
    }
  },
}
</script>

<style src="./nav.css" scoped></style>