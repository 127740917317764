<template>
    <div class="container" :style="style">
        <div class="ko-progress-circle" data-progress="0">
            <div class="ko-circle">
                <div class="full ko-progress-circle__slice">
                    <div class="ko-progress-circle__fill"></div>
                </div>
                <div class="ko-progress-circle__slice">
                    <div class="ko-progress-circle__fill"></div>
                    <div class="ko-progress-circle__fill ko-progress-circle__bar"></div>
                </div>
            </div>
            <div class="ko-progress-circle__overlay_containter">
                <div class="ko-progress-circle__overlay"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Progression Circle",
    props : ["pourcentage", "small"],
    watch : {
        pourcentage(val){
            if (val >= 0 && val <= 100){
                this.setPourcentage(val)
            }
        }
    },
    methods : {
        setPourcentage(pourcentage){
            $('.ko-progress-circle').attr('data-progress', Math.floor(pourcentage/100 * 100));
        }
    },
    mounted(){
        if (this.pourcentage != undefined){
            this.setPourcentage(this.pourcentage)
        } else {
            this.setPourcentage(0)
        }
    },
    computed :{
        style(){
            if (this.small != undefined && this.small == true){
                return "--circle-size : 48px; --inset-weight: 10px;"
            } else {
                return ""
            }
        }
    }
}
</script>

<style src="./style.scss" lang="scss"></style>