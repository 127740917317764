<template>
  <div class="dataListUser">
    <input type="text" v-model="valueSelected" list="users" ref="input" @change="changeValueSelected"
      @click="valueSelected = ''" />
    <datalist id="users">
      <option v-for="user in users" v-bind:key="user" v-bind:value="user.usr_email" v-bind:id="user._id">
        {{ user.usr_firstname }} {{ user.usr_lastname }}
      </option>
      <option value="all" id="all">
        Tous
      </option>
    </datalist>
  </div>
</template>
<script>
//NPM
import axios from "axios";

//STORE
import Vuex from "vuex";

export default {
  name: "DatalistUser",
  //props: ["currentUsrId"],
  emits: ["changeUser"],
  data() {
    return {
      selectedUser: { _id: null },
      valueSelected: "",
      users: [],
    };
  },
  mounted() {
    axios.get("/users").then((users) => {
      this.users = users.data;
      let index = this.users.findIndex((u) => u._id == this.getCurrentUsrId);

      if (index != -1) {
        this.selectedUser = this.users[index];
        this.valueSelected = this.users[index].usr_email;
      }
    });
  },
  methods: {
    ...Vuex.mapActions([
      "refresh_user_info",
      "set_usr_id_admin_changer",
      "loadVideos",
      "loadProjects"
    ]),
    changeValueSelected() {
      if (this.valueSelected != "") {
        let index = this.users.findIndex(
          (u) => u.usr_email == this.valueSelected
        );
        if (index != -1) {
          this.selectedUser = this.users[index];
          this.$nextTick(() => {
            //LOAD VIDEOS WITH CURRENT USER ID SET IN STORE 
            this.set_usr_id_admin_changer(this.selectedUser._id)
              .then(() => {
                if (this.$route.path == '/') {
                  this.loadProjects();
                } else if (this.$route.path == '/videos') {
                  this.loadVideos();
                }
              })

          });
        } else {
          //LOAD ALL VIDEOS
          if (this.$route.path == '/') {
            this.loadProjects(true);
          } else if (this.$route.path == '/videos') {
            this.loadVideos(true);
          }
        }
      }
    },
  },
  computed: {
    ...Vuex.mapGetters([
      "getCurrentUsrId",
    ]),
  },
};
</script>
<style scoped>
.dataListUser {
  position: absolute;
  /*
  left: calc(50% - 100px);
  top: 20px;
  */
  left: calc(50% - 100px);
  bottom: 20px;
  z-index: 30;
  opacity: 0.3;
}

.dataListUser:hover {
  opacity: 1;
}

input {
  width: 200px;
}
</style>