<template>
    <div class="progressBar_container">
        <span class="progressBar_filler" :theme="theme" :style="`width:${progression}%`"></span>
    </div>

</template>


<script>
export default {
  props: {
    progression : {},
    theme : {
        default : 'default'
    }
  },
    
}
</script>


<style lang="scss" src="./style.scss"></style>