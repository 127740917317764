<template>
    <div class="popContainerDiv" v-if="props.videoUrl != null">
        <div class="popUp">
            <video ref="videoPlayer" id="popUpVideoPlayer" :src="props.videoUrl" autoplay controls loop></video>
            <img class="exit" src="../../assets/close.svg" @click.stop="emit('closed')"/>
        </div>
    </div>
    <span v-if="videoUrl != null" class="focusBackground"></span>
</template>


<script setup>
import { ref, defineProps, watch, defineEmits, onMounted, onUnmounted } from "vue"

const emit = defineEmits(["closed"]);

const videoPlayer = ref()

const props = defineProps(['videoUrl']);

onMounted(()=>{
    document.addEventListener("keydown", listenEscapeKey);
})

function listenEscapeKey(e){
    if (e.key == "Escape" || e.key == "Esc") {
        emit('closed')
      }
}

onUnmounted(()=>{
    document.removeEventListener("keydown", listenEscapeKey)
})


watch(() => props.videoUrl, () => {
    //videoPlayer.value.play()
})

</script>


<style lang="scss" scoped>
    .popContainerDiv{
        top :0;
        left:0;
        position: fixed;
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
    }
    
    .popUp{
        padding: 0;
        width: 70vw;
        position: relative;
        & > #popUpVideoPlayer{
            border-radius: 12px;
            position: relative;
            margin: 0;
        }

        & > .exit{
            --size:35px;
            position: absolute;
            right: 10px;
            top:10px;
            width: var(--size);
            height: var(--size);
            cursor: pointer;
        }
    }

    .focusBackground{
        top :0;
        left:0;
        position: fixed;
        z-index: 1000;
        background-color: #00000050;
        width: 100vw;
        height: 100vh;
    }
</style>