<template>
    <div class="onboarder_container" v-if="dashboard_visible">
        <span class="shadow center"></span>

        <PopUp :videoUrl="popUpVideo" @closed="popUpVideo = null"/>

        <div class="inner center">
            <img src="../../../assets/trophy_color.svg" class="trophy_icon" />
            <p class="title center">{{ $t("onboarding.challenge") }}</p>
            <h2>{{ $t(`onboarding.your_score`) }}</h2>
            <h2 class="percent">{{ onboarding_tasks_completion }}%</h2>
            <span class="exit" @click="hide_onboarding_dashboard">-</span>
            <ProgressBar class="progression center" :progression="onboarding_tasks_completion" theme="shiny" />

            <div class="tasks">
                <p class="task center" v-for="task in onboarding_tasks" :key="task">
                    <label v-if="task.completed == false" class="unchecked"></label>
                    <img v-else src="../../../assets/checked.png" class="checked" />
                    <a class="label" :class="{ 'completed': (task.completed == true) }">{{ $t('onboarding.tasks.' + task.task_id + '.label') }}</a>
                    <!-- <img src='../../../assets/info.svg' @mouseover="playvideo(task.task_id)" class="tooltip" v-tippy="{ maxWidth: 'none', theme: 'light', placement: 'right', allowHTML: true, content: task.htmlTippy }" /> -->
                    <img src='../../../assets/info.svg' @click.stop="launchPopUp(task.video_url)" class="tooltip" />
                </p>
            </div>
        </div>
    </div>
</template>


<script>
import Vuex from "vuex"

import ProgressBar from '../ProgressBar/ProgressBar.vue'

import PopUp from "src/components/Object/PopUp.vue"

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default {
    components: {
        ProgressBar,
        PopUp
    },
    data(){
        return {
            popUpVideo : null
        }
    },
    computed: {
        ...Vuex.mapGetters(["dashboard_visible", "onboarding_tasks", "onboarding_tasks_completion"])
    },
    methods: {
        ...Vuex.mapActions(["hide_onboarding_dashboard"]),
        /*
        async playvideo(task_id) {
            while (document.getElementById(`tippyVideo_${task_id}`) == null) {
                await sleep(100)
            }
            document.getElementById(`tippyVideo_${task_id}`).currentTime = 0;
            document.getElementById(`tippyVideo_${task_id}`).play()
        }
        */
       launchPopUp(videoUrl){
        this.popUpVideo = videoUrl
        console.log('on change')
       }
    }
}
</script>


<style lang="scss" src="./style.scss"></style>