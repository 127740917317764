<template>
  <div class="box"
    v-tippy="{ allowHTML: true, content: tippyContent, placement: 'bottom', maxWidth: 500, trigger: (!subscriptionInfos.paymentFailed ? 'mouseenter focus' : 'manual') }">
    
    <!-- FREE MODE -->
    <!--
      <template v-if="subscriptionInfos.type == 'Free'">
        <p @click="upgrade" class="upgrade">{{ $t("Upgrade") }}</p>
      </template>
    -->

    <template v-if="!subscriptionInfos.paymentFailed">
      <ProgressionCircle :pourcentage="creditUsedPercent" :small="true" class="upload_credit_progression" />
      <span class="percentage">{{ creditUsedPercent }}%</span>
      <div style="margin-left: 10px; text-align: left;">
        <p style="font-weight: bold;font-size:16px;"><a style="color:var(--color1)">{{ subscriptionInfos.type }}</a> {{
          $t('credit_info.upload_limits') }}</p>
        <p>{{ $t('credit_info.you_used') }} : {{ displayTime(subscriptionInfos.credits) }}</p>
      </div>
      <p v-if="subscriptionInfos.type == 'Free'" @click="upgrade" class="upgrade">{{ $t("Upgrade") }}</p>
    </template>

    <!-- PAYMENT FAILED -->
    <template v-else>
      <img src="../../assets/warning.svg" class="warning_picto" />
      <div style="margin-right: 5px; text-align: left;">


        <p>{{ $t('credit_info.subscription_name_before') }} <a style="color:var(--color1)">{{ subscriptionInfos.type
        }}</a>
          {{ $t('credit_info.subscription_name_after') }}</p>
        <p>{{ $t('credit_info.payement_failed') }}</p>
      </div>
      <p class="upgrade" @click="openBillingPortal()">{{ $t('credit_info.pay') }}</p>
    </template>
  </div>
</template>

<script>
//COMPONENTS
import ProgressionCircle from "./ProgressionCircle/ProgressionCircle.vue";

import { renderSeconds } from "../../lib/date-time-duration"

//STORE
import Vuex from "vuex";

export default {
  components: {
    ProgressionCircle
  },
  computed: {
    ...Vuex.mapGetters([
      "subscriptionInfos",
      "getCurrentUsrId"
    ]),
    creditInitial() {
      if (this.getCurrentUsrId == "66b3008ab93ad35f1392bf45"){
        return (180*60) + this.subscriptionInfos.one_time_credits
      }

      switch (this.subscriptionInfos.type) {
        case "Free":
          return (15 * 60) + this.subscriptionInfos.one_time_credits;

        case "Starter":
          return (60 * 60) + this.subscriptionInfos.one_time_credits;

        case "Pro":
          return (180 * 60) + this.subscriptionInfos.one_time_credits;

        case "Unlimited":
          return (600 * 60) + this.subscriptionInfos.one_time_credits;

        default:
          return (15 * 60) + this.subscriptionInfos.one_time_credits;
      }
    },
    creditUsedPercent() {
      let percent = (((this.creditInitial - this.subscriptionInfos.credits) * 100) / this.creditInitial)
      return Math.floor(percent)
    },
    tippyContent() {
      let set = ``
      if (this.subscriptionInfos.one_time_credits > 0) {
        set += `<a style="font-weight : 600">${this.$t('credit_info.one_time_credits')}</a> : ${this.displayTime(this.subscriptionInfos.one_time_credits)}<br/>`
      }
      set += `<a style="font-weight : 600">${this.$t('credit_info.recurring_credits')}</a> : ${this.displayTime(this.subscriptionInfos.recurring_credits)}<br/><br/>`

      set += `&#9888 ${this.$t('credit_info.tippy_explications')}`
      return set
    }
  },
  methods: {
    ...Vuex.mapActions(["openBillingPortal"]),
    upgrade() {
      this.$router.push('/profile/subscriptions')
    },
    displayTime: renderSeconds
  }
}
</script>

<style scoped>
.box {
  position: relative;
  border: var(--border_weight) solid var(--color2);
  border: none;
  border-radius: var(--border_radius);
  padding: 9px;
  padding-left: 12px;
  padding-right: 14px;
  font-weight: 600;
  text-align: right;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 15px -6px rgba(0, 0, 0, 0.3);
  align-items: center;
}

.percentage {
  position: absolute;
  top: 23px;
  left: 20px;
  transform-origin: 50%;
  z-index: 2;
  font-size: 13px;
  text-align: center;
  width: 30px;
}

.upload_credit_progression {
  position: relative;
}

.upgrade {
  --border_radius: 12px;
  border-radius: var(--border_radius) var(--border_radius) var(--border_radius) var(--border_radius);
  background: linear-gradient(white, white) padding-box, linear-gradient(158deg, #3d3393, #2b76b9 31%, #2cacd1 57%, #35eb93) border-box;
  border: 4px solid transparent;
  padding: 9px;
  margin-left: 12px;
  cursor: pointer;
  transition: color 0.2s
}

.upgrade:hover {
  color: var(--color1);

}

.warning_picto {
  height: 35px;
  margin-right: 12px;
}
</style>