function countWord(script) {
  var wordCount = 0;
  script.forEach((script_element) => {
    wordCount += script_element.scrpt_text.split(/\n| /).length || 0;
  });
  return wordCount;
}

//RENDER MS TO "(M)M min (S)S s
function renderMs(element) {
  if (element != undefined) {
    element = element / 1000;
    var minutes = Math.floor(element / 60);
    var seconds = (element - minutes * 60).toFixed(0);
    if (seconds == "0") {
      if (minutes == 0) {
        return `${seconds} s`;
      } else {
        return `${minutes} min`;
      }
    } else if (minutes == 0) {
      return `${seconds} s`;
    } else {
      return `${minutes} min ${seconds} s`;
    }
  } else {
    return "-";
  }
}

// RENDER MS => MM:SS
function renderMsShort(duration) {
  duration = duration / 1000;
  if (duration != undefined) {
    var minutes = Math.floor(duration / 60);
    var seconds = (duration - minutes * 60).toFixed(0);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return addLeadingZeroToTime(minutes + ":" + seconds);
  } else {
    return "-";
  }
}

function addLeadingZeroToTime(time) {
  try {
    var h = time.split(":")[0];
    if (String(h).length < 2) {
      h = "0" + h;
    }
    var m = time.split(":")[1];
    if (String(m).length < 2) {
      m = "0" + m;
    }

    return h + ":" + m;
  } catch {
    return "-";
  }
}

//DISPLAY DATE TO YYYY/MM/DD or MM/DD/YYYY
function displayDate(date, lang = "fr") {
  if (date != undefined) {
    date = new Date(date);
    let month =
      String(date.getMonth() + 1).length > 1
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
    let day =
      String(date.getDate()).length > 1 ? date.getDate() : "0" + date.getDate();
    if (lang == "fr") {
      return day + "/" + month + "/" + date.getFullYear();
    } else {
      return month + "/" + day + "/" + date.getFullYear();
    }
  } else {
    return "-";
  }
}

//DISPLAY SECONDS TO (H)H h (M)M min (S)S s
function renderSeconds(time) {
  let display = "";
  let dateTime = new Date(time * 1000).toISOString();
  let hours = dateTime.substring(11, 13);
  let minutes = dateTime.substring(14, 16);
  let secondes = dateTime.substring(17, 19);
  if (hours != "00") {
    display += Number(hours) + " h ";
  }
  if (minutes != "00") {
    display += Number(minutes) + " min ";
  }

  if (secondes != "00") {
    display += Number(secondes) + " s ";
  }

  if (display == "") {
    display = "0 s ";
  }

  return display;
}

export {
  renderMs,
  renderMsShort,
  countWord,
  addLeadingZeroToTime,
  displayDate,
  renderSeconds,
};
