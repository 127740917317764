<template>
  <div class="messageBar" v-if="browserCompatible == false">
    <a
      >Pour une meilleure expérience, nous vous conseillons d'utiliser Yopbox
      avec Chrome</a
    >
  </div>
</template>

<script>
import { browserDetect } from "../../../lib/browserDetect";
export default {
  data() {
    return {
      browserCompatible: true,
    };
  },
  created() {
    var browser = browserDetect();
    if (browser != "chrome") {
      this.browserCompatible = false;
    }
  },
};
</script>

<style scoped>
.messageBar {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 40;
  background-color: var(--color1);
  background-color: #3eb1c8b9;
  color: white;
  text-align: center;
  font-weight: 500;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>